@import "/src/stylesheets/variables.scss";
.DropdownContainer :global {
  color: $font-color !important;
  position: relative;
  border-radius: 10%;
  cursor: pointer;
  border: none !important;
  height: 18px !important;
  line-height: 18px !important;
  .ant-btn,
  .ant-btn-default {
    position: static !important;
    width: 25px !important;
  }
  .ant-dropdown-menu-item {
    :hover,
    :focus,
    :active {
      background-color: $button-color !important;
      color: white !important;
    }
  }
  svg {
    font-size: 20px;
  }
  .placeholder {
    :hover {
      background-color: $fade-color !important;
    }
  }
}

.menu {
  color: $font-color !important;
  background-color: $second-color !important;
  max-height: 150px;
  overflow-y: auto;
  :hover,
  :focus,
  :active {
    background-color: $button-color !important;
    color: white !important;
  }
  .menuBox {
    span {
      display: flex !important;
      align-items: center !important;
      gap: 0.325rem !important;
    }
  }
}
