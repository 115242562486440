@import "/src/stylesheets/variables.scss";
.filters :global {
  width: max-content;
  overflow: hidden;
  background-color: $second-color !important;
  color: $font-color !important;
  border: 0.3px solid $font-color !important;
  border-radius: 5px !important;
  cursor: pointer;
  .ant-select,
  .ant-select-single {
    height: 30px !important;
    font-size: 12px !important;
    color: $font-color !important;
    background-color: $second-color !important;
    color: $font-color !important;
  }
  .ant-select-selector {
    height: 30px !important;
    font-size: 12px !important;
    background-color: $second-color !important;
    border-radius: 5px !important;
    color: $font-color !important;
    display: flex !important;
    gap: 0.625rem !important;
  }
  .ant-select-selection-item {
    display: flex !important;
    gap: 0.5rem !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    height: 30px !important;
    line-height: 30px !important;
    align-items: center !important;
  }
  .ant-select-item {
    background-color: $second-color !important;
  }
  @media only screen and (max-width: 949px) {
    margin: 0;
  }
}
.optionLabel {
  font-weight: 600;
  font-size: 12px;
}
