@import "/src/stylesheets/variables.scss";
.mainBar {
  width: 6rem;
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    #ac0607,
    #fa4914,
    #fabf1b,
    #72d467,
    #5cac22
  );
  position: relative;
  .label {
    color: #262250;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    position: absolute;
    top: 0;
  }
  .verticalLeftLine {
    border-left: 3px solid #262250;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .verticalRightLine {
    border-right: 3px solid #262250;
    height: 100%;
    position: absolute;
    top: 0;
  }
}
