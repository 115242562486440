@import "/src/stylesheets/variables.scss";

.mainWindow {
  width: calc(100vw - 16px);
  padding: 0 2rem;
}
:global {
  .ant-spin-dot-item {
    background-color: $button-color !important;
  }
  .ant-spin-spinning {
    color: $button-color !important;
  }
}
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #fff;
  mix-blend-mode: normal;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  :global {
    .ant-select {
      border: 1px solid $border-color !important;
      border-radius: 2px;
      width: 60px !important;
    }
  }

  & button {
    background: $fade-color;
    border: none;
    padding: 2px;
    height: auto;
    width: 20px;
    margin: 0 5px;
    &:focus {
      background: $button-color;
      color: white;
    }
  }
  @media screen and (max-width: $max-size-3) {
    .createBtn {
      display: none;
      & button {
        margin-bottom: 20px;
      }
    }
  }
}

.itemContainer {
  padding: 0 2rem 0 1rem;
  text-align: left;
  white-space: break-spaces;
  overflow: hidden;
  // text-overflow: ellipsis;
  // @media only screen and (max-width: $max-size-2){
  //   max-width: calc(100vw - 150px);
  // }
  & p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 0;
    text-align: left;
  }
  & .starRating {
    margin-bottom: 20px;
    display: flex;
  }
  & .NoBorder {
    border-bottom: none !important;
  }

  & a {
    color: blue;
    &:hover {
      color: blue;
    }
  }
}

.classifierWrapper :global {
  width: max-content;
  line-height: 30px !important;
  [title]:after {
    content: attr(title);
    position: absolute;
    left: 50%;
    bottom: 100%; /* put it on the top */
    background-color: yellow;
    width: max-content;
    opacity: 0;
    -webkit-transition: opacity 0.001s ease-in-out;
    transition: opacity 0.001s ease-in-out;
  }

  [title]:hover:after {
    opacity: 1;
  }
  .classifier {
    display: inline-flex;
    gap: 0.5rem;
    line-height: 30px !important;
    height: 30px !important;
  }
  .childLabel {
    margin-left: 0.5rem;
  }
  .ant-checkbox-inner::after {
    border-color: white !important;
  }
  .ant-checkbox-checked {
    border: 0.3px solid white !important;
  }
  .ant-checkbox .ant-checkbox-input {
    color: $second-color !important;
    background-color: $second-color !important;
    border-color: $second-color !important;
    :hover {
      border: 0.3px solid white !important;
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
    color: $second-color !important;
    background-color: $second-color !important;
    border-color: white !important;
    :hover {
      color: $font-color !important;
      border-color: $font-color !important;
    }
  }
  .ant-btn,
  .ant-btn-default {
    background-color: $second-color !important;
    align-items: start !important;
    font-size: 12px !important;
    width: max-content !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    border: none !important;
    font-weight: 600 !important;
    height: 30px !important;
    text-transform: capitalize !important;
    cursor: pointer !important;
    color: $font-color !important;
    .anticon-spin {
      color: $font-color !important;
      :hover {
        color: white !important;
      }
    }

    ::selection {
      color: $button-color !important;
    }
  }
  button {
    color: $font-color !important;
    border-radius: 8px !important;
    span {
      color: $font-color !important;
    }
  }
}
.lightModeClassifier :global {
  width: max-content;
  line-height: 30px !important;
  .classifier {
    display: inline-flex;
    gap: 0.5rem;
  }
  .childLabel {
    margin-left: 0.5rem;
  }
  .ant-checkbox-inner:after {
    border-color: $button-color !important;
  }
  .ant-checkbox-checked {
    border: 0.3px solid white !important;
  }
  .ant-checkbox .ant-checkbox-input {
    color: $second-color !important;
    margin-right: 0.5rem !important;
    background-color: $second-color !important;
    border-color: $second-color !important;
    :hover {
      border: 0.3px solid white !important;
    }
  }
  .ant-checkbox .ant-checkbox-inner {
    border-radius: 0;
    color: $font-color !important;
    background-color: $second-color !important;
    border-color: $button-color !important;
    :hover {
      color: white !important;
      border-color: $font-color !important;
    }
    span {
      font-size: 12px !important;
    }
  }
  .ant-btn,
  .ant-btn-default {
    z-index: 10 !important;
    background-color: $second-color !important;
    align-items: start !important;
    font-size: 12px !important;
    width: max-content !important;
    padding: 0 10px !important;
    border-radius: 5px !important;
    font-size: 12px !important;
    border: none !important;
    font-weight: 600 !important;
    height: 30px !important;
    text-transform: capitalize !important;
    cursor: pointer !important;
    color: $font-color !important;
    :hover,
    :focus,
    :active {
      color: white !important;
    }
    .anticon-spin {
      color: $font-color !important;
      :hover {
        color: white !important;
      }
    }

    ::selection {
      color: $button-color !important;
    }
  }
  button {
    z-index: 10 !important;
    color: $font-color !important;
    border-radius: 8px !important;
    span {
      color: $font-color !important;
      :hover {
        color: white !important;
      }
    }
    :hover {
      color: white !important;
    }
  }
}
.classifierChecks {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 1rem;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}
.feedbackMode {
  cursor: pointer;
  display: flex;
  column-gap: 5px;
  width: max-content;
  padding: 5px;
  color: black;
  margin-top: 10px;
  border-radius: 3px;
  line-height: 1.5rem;
  .feedback {
    line-height: 1.5rem;
  }
  :global {
    .ant-switch {
      line-height: 1.5rem !important;
    }
    .ant-switch-loading {
      color: $button-color !important;
      background-color: $fade-color !important;
    }
    .ant-switch-checked {
      background-color: $button-color !important;
    }
  }
}
.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
}
.firstLine {
  display: inline-flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
  line-height: 30px;
  height: 30px;
  .engagements {
    align-self: flex-start;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    .count {
      font-size: 14px;
      @media only screen and (max-width: 599px) {
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 599px) {
      gap: 1rem;
    }
  }
  .itemDate {
    align-self: flex-end;
    color: black;
    font-family: "Poppins";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2px;
    justify-content: space-evenly;
    align-items: center;
    width: 200px;
    justify-items: center;
    line-height: 21px;
    margin-right: 10px;
    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: auto;
      margin-right: 0px;
      font-size: 12px;
      margin-top: 0;
    }
  }
}
.reviewText {
  line-height: 180%;
  letter-spacing: 0.1px;
  .highlight {
    color: black;
    font-weight: 600;
    border-radius: 5px;
  }
}
.reviewTextWithNoSelect {
  line-height: 180%;
  letter-spacing: 0.1px;
  .highlight {
    color: black;
    font-weight: 600;
    border-radius: 5px;
  }
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.reviewFooter {
  margin-bottom: 0.425rem;
  span {
    color: $font-color;
    letter-spacing: 0.3px;
    font-weight: 600;
    font-size: 14px;
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
  .review_id {
    display: flex;
    gap: 2rem;
    @media only screen and (max-width: 450px) {
      flex-direction: column;
      gap: 0.325rem;
    }
  }
}
.itemBottom {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.25rem;
  .placeholder {
    img {
      margin-right: 0.25rem;
    }
  }
  .feedbackActions {
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
    .actions {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      height: 30px;
      line-height: 30px;
      align-items: last baseline;
      .discardBtn {
        background: $fade-color !important;
        color: $font-color;
        padding: 0 0.625rem;
        font-size: 12px;
        font-weight: 600;
        height: 30px;
        line-height: 30px;
        &:hover {
          color: $font-color !important;
        }

        &::selection {
          color: $button-color !important;
        }
      }
      .applyBtn {
        color: white !important;
        border: 0;
        padding: 0 0.625rem;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        font-weight: 600;
        background: $button-color !important;
        cursor: pointer;
        :hover,
        ::after,
        :active,
        :focus {
          color: white !important;
          background-color: $button-color !important;
        }
        .anticon-spin {
          color: $font-color !important;
          :hover {
            color: white !important;
          }
        }

        ::selection {
          color: $button-color !important;
        }
      }
      & button {
        color: #fff !important;
        border-radius: 5px;
        border: 0;
        height: 30px;
        line-height: 30px;
        &:focus {
          background: $fade-color;
        }
        span {
          color: white;
        }
      }
    }
  }
  .itemProps :global {
    display: inline-flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    justify-content: left;
    .ant-select-selection-placeholder {
      align-items: flex-start !important;
      height: 30px !important;
      overflow: hidden !important;
      border-radius: 5px !important;
      color: $font-color !important;
      font-weight: 600 !important;
      padding: 0;
      font-size: 12px !important;
      line-height: 30px !important;
      border-radius: 5px !important;
      span > .anticon {
        color: white !important;
        font-weight: 600 !important;
        line-height: 30px !important;
        padding: 0 0.375rem !important;
      }
    }
  }
  .placeholder :global {
    margin-right: 0.625rem !important;
    .placeholderIcon {
      color: $font-color !important;
    }
    .anticon {
      color: $font-color !important;
    }
  }
  .actionsPlaceholder {
    color: $font-color !important;
    font-weight: 600 !important;
    padding: 0;
    font-size: 12px !important;
    line-height: 30px;
  }
  .newSelectionItems {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.5rem;
    .firstLineItems {
      display: flex;
      flex-wrap: nowrap;
      gap: 0.5rem;
      @media only screen and (max-width: 949px) {
        flex-wrap: wrap;
      }
    }
    .bottomLineItems {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
  .intentsBlock {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    cursor: pointer;
  }
  .itemIntent {
    padding: 0 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }
  .pivotValues {
    padding: 0 8px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    text-transform: capitalize;
    background-color: #742de0;
    color: white;
  }
  & .itemRelatedTopics {
    padding: 0 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    gap: 0.625rem;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    .topicLabel {
      font-size: 12px;
      font-weight: 600;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
      display: flex;
      gap: 0.625rem;
      align-items: center;
      text-transform: capitalize;
      .label {
        span {
          max-width: 12rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .tagIcon {
      margin-top: 4px;
      line-height: 30px;
      font-size: 14px;
    }
  }
  .childTopicsWrapper :global {
    border: 0.3px solid $font-color;
    border-radius: 5px;
    width: max-content;
    align-items: baseline;
    line-height: 30px;
    .childTopic {
      display: inline-flex;
      gap: 0.5rem;
    }
    .childLabel {
      margin-left: 0.5rem;
    }
    .ant-checkbox-inner::after {
      border-color: white !important;
    }
    .ant-checkbox-checked {
      border: 0.3px solid white !important;
    }
    .ant-checkbox .ant-checkbox-input {
      color: $second-color !important;
      background-color: $second-color !important;
      border-color: $second-color !important;
      :hover {
        border: 0.3px solid white !important;
      }
    }
    .ant-checkbox .ant-checkbox-inner {
      border-radius: 0;
      color: $second-color !important;
      background-color: $second-color !important;
      border-color: white !important;
      :hover {
        color: $font-color !important;
        border-color: $font-color !important;
      }
    }
    .ant-btn,
    .ant-btn-default {
      background-color: $second-color !important;
      align-items: start !important;
      font-size: 12px !important;
      width: max-content !important;
      padding: 0 10px !important;
      border-radius: 5px !important;
      font-size: 12px !important;
      border: none !important;
      font-weight: 600 !important;
      height: 30px !important;
      text-transform: capitalize !important;
      cursor: pointer !important;
      color: $font-color !important;
      .anticon-spin {
        color: $font-color !important;
        :hover {
          color: white !important;
        }
      }

      ::selection {
        color: $button-color !important;
      }
    }
    button {
      color: $font-color !important;
      border-radius: 8px !important;
      height: 30px;
      line-height: 30px;
      span {
        color: $font-color !important;
      }
    }
  }
  .lightModeChildTopics :global {
    border: 0.3px solid $font-color;
    border-radius: 5px;
    width: max-content;
    align-items: baseline;
    line-height: 30px;
    // :hover {
    //   color: white !important;
    // }
    .childTopic {
      display: inline-flex;
      gap: 0.5rem;
    }
    .childLabel {
      margin-left: 0.5rem;
    }
    .ant-checkbox-inner:after {
      border-color: $button-color !important;
    }
    .ant-checkbox-checked {
      border: 0.3px solid white !important;
    }
    .ant-checkbox .ant-checkbox-input {
      color: $second-color !important;
      margin-right: 0.5rem !important;
      background-color: $second-color !important;
      border-color: $second-color !important;
      :hover {
        border: 0.3px solid white !important;
      }
    }
    .ant-checkbox .ant-checkbox-inner {
      border-radius: 0;
      color: $font-color !important;
      background-color: $second-color !important;
      border-color: $button-color !important;
      :hover {
        color: white !important;
        border-color: $font-color !important;
      }
      span {
        font-size: 12px !important;
      }
    }
    .ant-btn,
    .ant-btn-default {
      z-index: 10 !important;
      background-color: $second-color !important;
      align-items: start !important;
      font-size: 12px !important;
      width: max-content !important;
      padding: 0 10px !important;
      border-radius: 5px !important;
      font-size: 12px !important;
      border: none !important;
      font-weight: 600 !important;
      height: 30px !important;
      text-transform: capitalize !important;
      cursor: pointer !important;
      color: $font-color !important;
      // :hover,
      // :focus,
      // :active {
      //   color: white !important;
      // }
      .anticon-spin {
        color: $font-color !important;
        // :hover {
        //   color: white !important;
        // }
      }

      ::selection {
        color: $button-color !important;
      }
    }
    button {
      z-index: 10 !important;
      color: $font-color !important;
      border-radius: 8px !important;
      height: 30px;
      line-height: 30px;
      // span {
      //   color: $font-color !important;
      //   :hover {
      //     color: white !important;
      //   }
      // }
      // :hover {
      //   color: white !important;
      // }
    }
  }
  & .itemSentiment {
    padding: 5px;
    background: rgba(235, 87, 87, 0.2);
    border-radius: 5px;
    color: red;
    display: inline-block;
  }
  :global {
    div.ant-typography,
    .ant-typography p {
      margin-bottom: auto;
    }
    & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
      border: none;
    }
  }
}

.searchFilters {
  color: inherit;
}

.searchFilters {
  margin: 0 auto;
  padding: 20px 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    margin: 20px auto 0 auto;
  }
  .filterFirstRow {
    display: flex;
    flex-wrap: wrap;
  }
  :global {
    .ant-tag {
      background: $button-color !important;
      padding: 8px 16px;
      margin: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      border-radius: 20px;
      min-width: 60px;
      color: white !important;
      white-space: break-spaces;
      border: none;
      & span {
        margin-left: 10px;
        font-size: 15px;
        color: $primary-color;
      }
      .ant-tag-close-icon {
        color: white;
        font-size: 14px !important;
        top: 0 !important;
      }
    }
  }
  .reset {
    margin-top: 12px;
    text-decoration: underline;
    font-size: 16px;
    height: max-content;
    margin-left: 20px;
    flex-shrink: 0;
    cursor: pointer;
    @media only screen and (max-width: 1100px) {
      text-align: right;
      display: block;
      width: 100%;
      margin-left: 0px;
    }
  }
}

.checkbox {
  & label {
    margin-right: 20px;
    top: 3px;
    position: relative;
  }
}
.reviews {
  text-align: center;
}

.createBtn {
  & button {
    color: #fff;
    background: $button-color;
    border-radius: 8px;
    width: 200px;
    height: 40px;
  }
  & i {
    margin-right: 10px;
  }
}

.tweet {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &Pic {
    max-width: 50px;
    border-radius: 50%;
  }

  &Left {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .ratingText {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $font-color;
  }
  &Likes {
    display: flex;

    & div {
      margin-right: 30px;

      & p {
        text-align: center;
      }
    }
    .twitter {
      max-width: 45px;
      margin-top: 0px !important;
    }

    // & img {
    //   max-width: 35px;
    //   margin-top: -30px;
    // }
  }

  &Name {
    margin-left: 15px;

    & h5 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 5px;
      white-space: nowrap;
    }

    &Verified {
      display: flex;

      & svg {
        width: 22px;
        height: 22px;
        color: rgb(217, 217, 217);
        fill: currentcolor;
        margin-left: 5px;
        margin-top: -2px;
      }
    }
  }
}

.facebookCommentRight {
  @media only screen and (max-width: 450px) {
    button {
      font-size: 12px !important;
      padding: 5px 8px !important;
      width: max-content !important;
    }
  }
}

.facebookComment {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  & .checkbox {
    label {
      @media only screen and (max-width: 450px) {
        margin-right: 10px !important;
      }
    }
  }

  &Pic {
    max-width: 40px;
    border-radius: 50%;
    @media only screen and (max-width: 450px) {
      max-width: 30px;
    }
  }

  &Left {
    display: flex;
    align-items: center;
  }

  &Right {
    display: flex;
    align-items: center;

    & div {
      margin-right: 30px;

      & p {
        text-align: center;
      }
    }

    & img {
      max-width: 35px;
      margin-top: -30px;
    }
  }

  &Name {
    margin-left: 15px;
    @media only screen and (max-width: 450px) {
      margin-left: 8px;
      font-size: 13px !important;
    }

    & p {
      font-size: 16px;
      @media only screen and (max-width: 450px) {
        font-size: 14px !important;
      }
    }

    & h5 {
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 5px;
      white-space: nowrap;
    }

    &Verified {
      display: flex;

      & svg {
        width: 22px;
        height: 22px;
        color: rgb(217, 217, 217);
        fill: currentcolor;
        margin-left: 5px;
        margin-top: -2px;
      }
    }
  }
}

.paginationWrapper {
  padding: 30px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 380px) {
    justify-content: center;
  }

  .innerWrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media only screen and (max-width: 600px) {
      margin-right: 0;
    }
    @media only screen and (max-width: 380px) {
      flex-direction: column;
      justify-content: center;
    }

    .paginationBox {
      button {
        background-color: transparent !important;
        color: $font-color !important;
        border: 0px !important;
        @media only screen and (max-width: 600px) {
          padding: 4px 15px 4px 0 !important;
        }
      }
      .pagination {
        font-size: 16px;
        display: inline-block;
        input {
          display: inline-block;
          margin-right: 10px;
          border: 1px solid $font-color;
          border-radius: 4px;
          outline: none;
          width: 70px;
          height: 30px;
          padding: 0 8px;
          background-color: transparent;
          @media only screen and (max-width: 600px) {
            width: 60px;
          }
        }
        @media only screen and (max-width: 600px) {
          padding-right: 15px;
          font-size: 14px;
        }
      }
    }
    & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent !important;
    }
  }
  .reviewsTotal {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

// REPLY BOX MODAL
.replyBoxModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .replyBoxModalContent {
    background-color: $box-color;
    max-width: 800px;
    border-radius: 6px;
    padding: 20px 25px;

    .headingBox {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .titleBox {
        :first-child {
          font-size: 18px;
          text-align: left;
          margin: 0 !important;
        }
        :last-child {
          font-size: 13px;
          margin-top: 8px;
          display: flex;
          align-items: center;
          color: $font-color;
          svg {
            display: inline-block;
            margin-right: 5px !important;
          }
        }
      }
      img {
        cursor: pointer;
        display: inline-block;
      }
    }
    .userNameDetails {
      background-color: $second-color;
      margin-top: 20px;
      border-radius: 6px;
      padding: 16px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      :first-child {
        img {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin: 0;
          margin-right: 5px;
        }
      }
      :last-child {
        text-align: left !important;
        margin-top: 12px;
        font-size: 13px;
        margin-bottom: 0;
      }
    }
    .replyBox {
      margin-top: 32px;
      padding: 12px 21px;
      border: 1px solid #6f6c99;
      border-radius: 6px;
      display: flex;
      align-items: center;

      textarea {
        display: inline-block;
        resize: none;
        border: none;
        background-color: transparent;
        flex-grow: 1;
        outline: none;
        padding-right: 20px;
        overflow: hidden;
        ::-webkit-scrollbar {
          display: none;
        }
      }
      button {
        outline: none;
        border: none;
        padding: 10px 32px;
        font-size: 14px;
        font-weight: 600;
        font-family: "Poppins";
        background-color: $button-color;
        border-radius: 6px;
        cursor: pointer;
        letter-spacing: 0.4px;
        color: white;
      }
    }

    @media only screen and (max-width: 900px) {
      max-width: 600px;
    }
    @media only screen and (max-width: 600px) {
      max-width: 350px;
      .userNameDetails {
        margin-top: 0;
      }
      .replyBox {
        margin-top: 10px;
        padding: 8px 12px;
        border: 1px solid #6f6c99;
        border-radius: 6px;
        display: flex;
        align-items: center;

        textarea {
          font-size: 13px;
        }
        button {
          padding: 6px 15px !important;
          font-size: 13px;
        }
      }
    }
  }
}

.npsTag {
  display: flex;
  align-items: center;
  span {
    padding: 2px 8px;
    display: inline-block;
    border-radius: 3px;
    margin: 0 5px;
    color: black;
    &:first-child {
      background-color: #fcc931;
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        margin-left: 4px;
      }
    }
  }
}
.roundIcon {
  margin: 0 !important;
  width: 25px;
  height: 25px;
  padding: 2px !important;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin-right: 5px !important;
  img {
    width: 100%;
    height: 100%;
  }
}
.showHideTopicLabel {
  background-color: $body-color;
  border-radius: 6px;
  padding: 5px 6px;
  position: relative;
  color: $font-color;
  @media only screen and (max-width: 500px) {
    padding: 0 10px;
    margin-left: 12px;
  }
  .showHidePopupBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 5px 6px;
    @media only screen and (max-width: 500px) {
      height: 100%;
      .showText {
        display: none;
      }
    }
  }
  .eye {
    svg {
      font-size: 17px;
    }
  }
}
.showTopicPopup {
  position: absolute;
  top: 100%;
  right: 0;
  width: max-content;
  z-index: 9;
  padding: 5px 12px;
  border-radius: 6px;
  background-color: $box-color;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  width: 280px;
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    img {
      margin-right: 5px;
      display: inline-block;
    }
    &:first-child {
      img {
        margin-right: 0px;
      }
    }
    .eye {
      cursor: pointer;
      margin-right: 0;
    }
    .eyeClose {
      cursor: pointer;
      svg {
        font-size: 17px;
      }
    }
  }
}
.mobileViewActionBlock {
  position: relative;
  color: $font-color;
  @media only screen and (max-width: 500px) {
    display: block;
  }
  button {
    background-color: $button-color !important;
    padding: 5px 6px;
    width: max-content;
    border-radius: 3px;
    i {
      padding: 5px 6px;
      font-size: 17px;
      color: white;
    }
  }
  .mobileActionPopup {
    position: absolute;
    top: 100%;
    right: 0;
    width: max-content;
    z-index: 9;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: $box-color;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    width: 200px;
    span {
      display: block;
      text-align: left;
      padding: 6px;
      cursor: pointer;
    }
  }
}
.emptyStateContent {
  margin-top: 10px;
  .docLink {
    color: $font-color;
    text-decoration: underline;
    margin-top: 10px;
    &:hover {
      color: $font-color !important;
    }
  }
}

.reviewTitle {
  color: black;
  font-size: 14px;
  font-weight: 600;
  margin: 0.825rem 0 !important;
  text-align: left;
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.hideOverflows {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
}
.showMoreAssociations {
  display: flex;
  color: $button-color;
  font-size: 12px;
  font-weight: 600;
  padding: 0 10px 10px 10px;
  cursor: pointer;
  justify-content: flex-end;
  align-items: first baseline;
}

.decisionTags {
  padding: 0 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  text-transform: capitalize;
  background-color: #c93eb4;
  color: white;
  cursor: pointer;
}
