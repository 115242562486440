:root {
	--color1: #100d31;
	--color2: #1c193b;
	--color3: #4400aa;
	--color4: #6f6c99;
	--color5: #262250;
	--color6: #ef0055;
	--body: #100d31;
	--font: #ffffff;
	--border: #262250;
	--active: #4400aa;
	--secondary: #1c193b;
	--line: #262250;
	--popup: #262250;
	--bar1:#4a99e3;
	--bar2:#78fa78;
	--bar3:rgba(105, 129, 217, 0.953);
	--bar4: #dcfa45;
	--bar5: #fc9ae7;
}
$primary-color: var(--color1);
$secondary-color: var(--color2);
$active-color: var(--color3);
$fade-color: var(--color4);
$border-color: var(--color5);
$attention-color: var(--color6);
$body-color: var(--body);
$font-color: var(--font);
$layout-color: var(--border);
$second-color: var(--secondary);
$button-color: var(--active);
$line-color: var(--line);
$box-color: var(--popup);
$cat1-color:var(--bar1);
$cat2-color:var(--bar2);
$cat3-color:var(--bar3);
$cat4-color:var(--bar4);
$cat5-color:var(--bar5);
$max-size-1: 1100px;
$max-size-2: 750px;
$max-size-3: 500px;